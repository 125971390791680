<template>
  <div>
    <Banner :banner="bannerUrl" />
    <div class="mainboby" :class="{'px-3': device==='desktop'}">
      <div class="product-main-area">
        <content-loading type="product" v-if="isLoading || hotels.length===0"/>
        <div class="container" v-else>
          <hotel-info :hotel="hotels[0]"/>
          <slider :slides="imageList" v-if="this.imageList.length" :hotelName="hotelName"/>
          <div class="product-detailsbody-area">
            <div class="row">
              <div class="col-xl-9 col-lg-12 col-md-12 order-2 product-details">
                <b-tabs>
                  <info-vacation-package :infoPack="infoPack"/>
                </b-tabs>
              </div>
              <div class="col-xl-3 col-lg-12 col-md-12 order-1">
                <div class="product-detailsbody-right">
                  <price-area
                    :hotels="hotels"
                    :moreHotelInfo="moreHotelInfo"
                    :searchGuid="searchGuid"
                    :supplier="supplier"
                    :hotelName="hotelName"
                    :destinationCityName="destinationCityName"
                  />
                  <div class="d-flex align-items-center media-call">
                    <div class="flex-shrink-0 iconbox d-flex align-items-center justify-content-center">
                      <i class="fa-solid fa-phone-volume"></i>
                    </div>
                    <div class="flex-grow-1 media-body">
                      <p>{{ $t("product-page.encounter-message") }}</p>
                      <a href="tel:0508308880" class="link">050-8308880</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer page="product" v-if="!hideFooter"/>
  </div>
</template>

<script>
import { BTabs } from 'bootstrap-vue';
import TemplateProductPage from './TemplateProductPage';

export default {
  mixins: [TemplateProductPage],
  components: {
    BTabs,
    Banner: () => import('@/components/productPage/productBanner/ProductBannerTheme1'),
    InfoVacationPackage: () => import('@/components/productPage/tabInfo/InfoVacationPackageTheme1'),
    HotelInfo: () => import('@/components/productPage/hotelInfo/HotelInfoTheme1'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PriceArea: () => import('@/components/productPage/productPriceArea/PriceAreaTheme1'),
    Slider: () => import('@/components/productPage/productSlider/ProductSliderTheme1'),
    Footer: () => import('@/components/content/footer/FooterTheme1'),
  },
  data() {
    return {
      bannerUrl: 'assets/img/theme1/innerbanner.jpg',
    };
  },
  computed: {
    infoPack() {
      return {
        description: this.description,
        facilities: this.facilities,
        collapseData: this.collapseData,
        address: `${this.destinationCityName} ${this.hotelName}`,
      };
    },
  },
};
</script>

<style scoped>
.mainboby {
    padding: 48px 0px 100px;
}
.product-detailsbody-area{}
.product-details .tab-content{
    padding-top: 30px;
}
.product-details .nav {
    border-radius: 8px;
    padding: 10px 30px;
    border: none;
    background-color: #1993C8;
    position: relative;
    padding-bottom: 0;
}
.product-details .nav .nav-link {
    border: none;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 30px;
    width: 270px;
    text-align: center;
}
.product-details .nav .nav-link.active {
    color: #000000;
    position: relative;
    background-image: url(/assets/img/theme1/tabafterbg.png);
    background-size: contain;
    background-color: transparent;
    background-position: center bottom;
    background-repeat: no-repeat;
}
.product-details .product-body{}
.product-details .product-body div{
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.product-details .product-body > div {
    padding: 0;
    padding-right: 25px;
    margin: 0 0 20px;
    position: relative;
    font-size: 14px;
    font-weight: 300;
}
.product-details .product-body .list li::before{
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 15px;
    height: 15px;
    background-image: url(/assets/img/theme1/check3.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.product-detailsbody-area .product-detailsbody-right{ padding-left:24px; }
.product-detailsbody-area .product-detailsbody-right .media-call{
    background-color: #1993C8;
    border-radius: 10px;
    padding: 20px;
}
.product-detailsbody-area .product-detailsbody-right .media-call .iconbox {
    width: 60px;
    height: 60px;
    background-color: rgb(255 255 255 / 29%);
    border-radius: 50%;
    color: #ffffff;
    font-size: 25px;
    transform: rotate(-45deg);
}
.product-detailsbody-area .product-detailsbody-right .media-call .media-body{
    padding-right: 20px;
}
.product-detailsbody-area .product-detailsbody-right .media-call .media-body p{
    color: #ffffff;
    font-size: 15px;
    font-weight: 300;
    margin: 0 0 5px;
}
.product-detailsbody-area .product-detailsbody-right .media-call .media-body .link{
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    transition: 0.5s;
}
.product-detailsbody-area .product-detailsbody-right .media-call .media-body .link:hover{
    color: #000000;
}
@media (min-width: 1200px){
  .product-detailsbody-area .col-xl-3 {
    flex: 0 0 30%;
    width: 30%;
    max-width: 30%;
  }
  .product-detailsbody-area .col-xl-9 {
    flex: 0 0 70%;
    width: 70%;
    max-width: 70%;
  }
}
@media (max-width: 1199px){
  .product-detailsbody-area .product-detailsbody-right {
      padding-left: 0;
  }
  .product-detailsbody-area .order-2 {
      order: 1 !important;
  }
}
</style>
