import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import getProductBanner from '@/mixins/getProductBanner';

export default {
  data() {
    return {
      bookingStage: 1,

      personString: '',
      query: this.$route.query,
      packages: null,
      hotels: [],
      hotelCode: '',
      imageList: [],
      description: '',
      facilities: [],
      remarks: '',
      collapseData: [],

      searchGuid: '',
      supplier: '',
    };
  },
  mixins: [getProductBanner],
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      packagesDest: 'GET_PACKAGES',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      isRTLPage: 'GET_IS_RLT_PAGE',
      moreHotelInfo: 'GET_ONE_HOTEL_INFO',
      marketerId: 'GET_MARKETER_ID',
      hideCondition: 'GET_HIDE_CONDITION',
      agencyMarketerContent: 'GET_MARKETER_AGENCY_CONTENT',
    }),
    linkData() {
      return {
        text: this.hotelName,
        dest: this.destinationCityName,
        stage: `${this.query.checkOut.split('-').reverse().join('/')} - ${this.query.checkIn.split('-').reverse().join('/')}`,
      };
    },
    destinationCityName() {
      const filterArray = this.destinationList.find((item) => item.code === this.query.city);

      return (filterArray) ? filterArray.name : '';
    },
    betweenDateString() {
      let tripDuration = '';
      const checkIn = dayjs(this.query.checkIn, 'YYYY-MM-DD'), checkOut = dayjs(this.query.checkOut, 'YYYY-MM-DD');
      const duration = this.getDuration(checkIn, checkOut);
      if (this.lang === 'he') {
        tripDuration = `${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} -
           ${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} (${duration} ${this.$t('search-result.night')})`;
      } else {
        tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} -
            ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${duration} ${this.$t('search-result.night')})`;
      }
      return tripDuration;
    },
    basisName() {
      return this.hotels[0].basisName;
    },
    remark() {
      return this.supplier === 'ATLANTIS' ? this.hotels[0].remark : this.hotels[0].adminRemark;
    },
    hideFooter() {
      const hide = this.hideCondition || (this.marketerId !== '' && this.agencyMarketerContent?.marketerSpecificContents?.hideFooter);
      return hide;
    },
    hotelName() {
      return this.moreHotelInfo?.hotelConversion?.hotelName || this.hotels[0]?.name || '';
    },
  },
  watch: {
    packagesDest: 'setPackageUnderCity',
    packages: 'getHotels',
    moreHotelInfo: 'setHotelInfo',
    lang() {
      this.personString = this.getPersonString(Number(this.$route.query.adult), Number(this.$route.query.child), Number(this.$route.query.infant));
      this.updatedCollapsData();
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'product');

    /**
     * Remove the direct payment state from pending booking page when normal booking.
     */
    window.localStorage.removeItem('direct-payment');
    this.$store.commit('SET_DIRECT_PAYMENT_STATE', false);

    this.hotelCode = this.query.hotelCode;
    this.supplierCode = this.query.suppliers;

    if (!this.query.city || !this.query.checkIn || !this.query.checkOut || !this.query.adult || !this.query.suppliers || !this.query.hotelCode) {
      this.$bvModal.msgBoxOk(
        this.$t('search-result.dont-remove-query'),
        {
          title: this.$t('product-page.expire-title'),
          dialogClass: 'noSearchResult',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        },
      );
      return;
    }
    const body = {
      city: this.query.city,
      hotelCode: String(this.hotelCode),
      dealCategoryId: this.query.dealCategoryId,
      checkIn: this.query.checkIn,
      checkOut: this.query.checkOut,
      adult: Number(this.query.adult),
      child: Number(this.query.child),
      infant: Number(this.query.infant),
      lang: this.query.lang,
      includeFlight: null,
      suppliers: [this.supplierCode],
      returnLog: null,
    };

    await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', body);
    await this.$store.dispatch('FETCH_MORE_HOTEL_INFO', { hotelCode: this.hotelCode, supplierCode: this.supplierCode });
    await this.$store.dispatch('FETCH_DOMESTIC_HOTELS');

    window.localStorage.setItem('BookingDataID', '');
  },
  mounted() {
    this.personString = this.getPersonString(Number(this.$route.query.adult), Number(this.$route.query.child), Number(this.$route.query.infant));
  },
  methods: {
    setPackageUnderCity() {
      this.packages = this.packagesDest[this.query.city];
    },
    getHotels() {
      this.hotels = [];
      if (this.packages && this.packages.length > 0) {
        this.packages.forEach((item) => {
          this.searchGuid = item.searchGuid;
          this.supplier = item.supplierCode;

          item.data.data.forEach((elm) => {
            if (elm.exCode === this.hotelCode) {
              elm.searchGuid = item.searchGuid;
              elm.adminRemark = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].adminRemark : '';
              elm.agentCommPctFromRule = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].agentCommPctFromRule : null;
              elm.fcCommPctFromRule = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].fcCommPctFromRule : 0;
              elm.imgUrl = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].imageUrl : '';
              this.hotels.push(elm);
            }
          });
        });
      }
      if (this.hotels.length === 0) {
        window.location.href = `hotel-info/?city=${this.query.city}&hotelCode=${this.query.hotelCode}&lang=${this.lang}`;
      }
      document.querySelector('.st-content').scrollTo(0, 0);
    },
    setHotelInfo() {
      this.imageList = this.moreHotelInfo?.allImageUrls || [];
      if (this.query.mainimg) {
        let isExist = false;
        for (let i = 0; i < this.imageList.length; i += 1) {
          if (this.imageList[i] === this.query.mainimg) {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          this.imageList.unshift(this.query.mainimg);
        }
      }

      this.description = this.moreHotelInfo?.hotelDescription || '';
      this.facilities = this.moreHotelInfo?.facilities || [];

      this.remarks = this.moreHotelInfo?.remark || '';

      this.imageDescList = this.moreHotelInfo?.roomClasses?.map((item) => ({
        src: item?.imageUrls?.[0],
        name: item.name,
        description: item.description,
      }));
      this.updatedCollapsData();
      this.sendProductData();
    },
    updatedCollapsData() {
      this.collapseData = [
        {
          title: this.$t('product-page.about-hotel-info'),
          message: this.description,
          type: 'html',
        },
        {
          title: this.$t('search-result.hotel-facilities'),
          fac: this.facilities,
          type: 'fac',
        },
        {
          title: this.$t('product-page.hotel-rooms'),
          type: 'image',
          items: this.imageDescList,
        },
        {
          title: this.$t('product-page.map'),
          type: 'map',
          address: `${this.destinationCityName} ${this.hotelName}`,
        },
        // {
        //   title: this.$t('product-page.general-terms'),
        //   type: 'html',
        //   message: this.description,
        // },
      ];
    },
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
    getPersonString(adult, child, infant) {
      let str = (adult > 1) ? ` ${adult} ${this.$t('product-page.adults')}` : ` ${adult} ${this.$t('product-page.adult')}`;
      if (child) str += (child > 1) ? ` + ${child} ${this.$t('product-page.children')}` : ` + ${child} ${this.$t('product-page.child')}`;
      if (infant) str += (infant > 1) ? ` + ${infant} ${this.$t('product-page.infant')}` : ` + ${infant} ${this.$t('product-page.infants')}`;
      return str;
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    sendProductData() {
      const body = {
        destCity: [
          {
            code: this.query.city,
            name: this.destinationCityName,
          },
        ],
        hotel: [
          {
            id: this.moreHotelInfo.hotelId,
            source: this.supplierCode,
            code: this.query.hotelCode,
            name: this.hotelName,
          },
        ],
        reservationPeriod: [
          {
            checkin: this.query.checkIn,
            checkout: this.query.checkOut,
          },
        ],
        adults: +this.query.adult,
        children: +this.query.child,
        infant: +this.query.infant,
      };
      this.$store.dispatch('JOURNEY_PRODUCT_PAGES', { body });
    },
  },
};
